export const AVAILABLE_CITIES = [
  { id: "shanghai", city: "Shanghai", country: "China", zone: "Asia/Shanghai" },
  { id: "beijing", city: "Beijing", country: "China", zone: "Asia/Shanghai" },
  { id: "tokyo", city: "Tokyo", country: "Japan", zone: "Asia/Tokyo" },
  {
    id: "singapore",
    city: "Singapore",
    country: "Singapore",
    zone: "Asia/Singapore",
  },
  {
    id: "hongKong",
    city: "Hong Kong",
    country: "China",
    zone: "Asia/Hong_Kong",
  },
  { id: "seoul", city: "Seoul", country: "South Korea", zone: "Asia/Seoul" },
  { id: "dubai", city: "Dubai", country: "UAE", zone: "Asia/Dubai" },
  { id: "bangkok", city: "Bangkok", country: "Thailand", zone: "Asia/Bangkok" },
  { id: "taipei", city: "Taipei", country: "Taiwan", zone: "Asia/Taipei" },
  { id: "mumbai", city: "Mumbai", country: "India", zone: "Asia/Kolkata" },
  { id: "delhi", city: "New Delhi", country: "India", zone: "Asia/Kolkata" },
  {
    id: "jakarta",
    city: "Jakarta",
    country: "Indonesia",
    zone: "Asia/Jakarta",
  },
  { id: "osaka", city: "Osaka", country: "Japan", zone: "Asia/Tokyo" },
  {
    id: "kualaLumpur",
    city: "Kuala Lumpur",
    country: "Malaysia",
    zone: "Asia/Kuala_Lumpur",
  },
  { id: "manila", city: "Manila", country: "Philippines", zone: "Asia/Manila" },
  { id: "newYork", city: "New York", country: "USA", zone: "America/New_York" },
  {
    id: "losAngeles",
    city: "Los Angeles",
    country: "USA",
    zone: "America/Los_Angeles",
  },
  { id: "chicago", city: "Chicago", country: "USA", zone: "America/Chicago" },
  {
    id: "toronto",
    city: "Toronto",
    country: "Canada",
    zone: "America/Toronto",
  },
  {
    id: "sanFrancisco",
    city: "San Francisco",
    country: "USA",
    zone: "America/Los_Angeles",
  },
  {
    id: "vancouver",
    city: "Vancouver",
    country: "Canada",
    zone: "America/Vancouver",
  },
  { id: "miami", city: "Miami", country: "USA", zone: "America/New_York" },
  {
    id: "mexicoCity",
    city: "Mexico City",
    country: "Mexico",
    zone: "America/Mexico_City",
  },
  {
    id: "montreal",
    city: "Montreal",
    country: "Canada",
    zone: "America/Montreal",
  },
  { id: "boston", city: "Boston", country: "USA", zone: "America/New_York" },
  { id: "london", city: "London", country: "UK", zone: "Europe/London" },
  { id: "paris", city: "Paris", country: "France", zone: "Europe/Paris" },
  { id: "berlin", city: "Berlin", country: "Germany", zone: "Europe/Berlin" },
  { id: "madrid", city: "Madrid", country: "Spain", zone: "Europe/Madrid" },
  { id: "rome", city: "Rome", country: "Italy", zone: "Europe/Rome" },
  {
    id: "amsterdam",
    city: "Amsterdam",
    country: "Netherlands",
    zone: "Europe/Amsterdam",
  },
  { id: "moscow", city: "Moscow", country: "Russia", zone: "Europe/Moscow" },
  {
    id: "istanbul",
    city: "Istanbul",
    country: "Turkey",
    zone: "Europe/Istanbul",
  },
  {
    id: "barcelona",
    city: "Barcelona",
    country: "Spain",
    zone: "Europe/Madrid",
  },
  { id: "munich", city: "Munich", country: "Germany", zone: "Europe/Berlin" },
  {
    id: "zurich",
    city: "Zurich",
    country: "Switzerland",
    zone: "Europe/Zurich",
  },
  {
    id: "stockholm",
    city: "Stockholm",
    country: "Sweden",
    zone: "Europe/Stockholm",
  },
  { id: "vienna", city: "Vienna", country: "Austria", zone: "Europe/Vienna" },
  {
    id: "copenhagen",
    city: "Copenhagen",
    country: "Denmark",
    zone: "Europe/Copenhagen",
  },
  { id: "dublin", city: "Dublin", country: "Ireland", zone: "Europe/Dublin" },
  {
    id: "sydney",
    city: "Sydney",
    country: "Australia",
    zone: "Australia/Sydney",
  },
  {
    id: "melbourne",
    city: "Melbourne",
    country: "Australia",
    zone: "Australia/Melbourne",
  },
  {
    id: "brisbane",
    city: "Brisbane",
    country: "Australia",
    zone: "Australia/Brisbane",
  },
  { id: "perth", city: "Perth", country: "Australia", zone: "Australia/Perth" },
  {
    id: "auckland",
    city: "Auckland",
    country: "New Zealand",
    zone: "Pacific/Auckland",
  },
  {
    id: "saoPaulo",
    city: "São Paulo",
    country: "Brazil",
    zone: "America/Sao_Paulo",
  },
  {
    id: "rioDeJaneiro",
    city: "Rio de Janeiro",
    country: "Brazil",
    zone: "America/Sao_Paulo",
  },
  {
    id: "buenosAires",
    city: "Buenos Aires",
    country: "Argentina",
    zone: "America/Argentina/Buenos_Aires",
  },
  {
    id: "santiago",
    city: "Santiago",
    country: "Chile",
    zone: "America/Santiago",
  },
  { id: "lima", city: "Lima", country: "Peru", zone: "America/Lima" },
  { id: "cairo", city: "Cairo", country: "Egypt", zone: "Africa/Cairo" },
  {
    id: "johannesburg",
    city: "Johannesburg",
    country: "South Africa",
    zone: "Africa/Johannesburg",
  },
  { id: "lagos", city: "Lagos", country: "Nigeria", zone: "Africa/Lagos" },
  {
    id: "casablanca",
    city: "Casablanca",
    country: "Morocco",
    zone: "Africa/Casablanca",
  },
  { id: "nairobi", city: "Nairobi", country: "Kenya", zone: "Africa/Nairobi" },
];

export const DEFAULT_CITIES = [
  "newYork",
  "london",
  "tokyo",
  "beijing",
  "sydney",
];

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
  SYSTEM: "system",
};
