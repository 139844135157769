import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

export const useTimeUpdate = (timeZones, use24Hour, setTimes) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const updateTimes = () => {
      const newTimes = {};
      timeZones.forEach(({ city, zone }) => {
        const date = new Date();
        newTimes[city] = {
          time: date.toLocaleTimeString(i18n.language, {
            timeZone: zone,
            hour12: !use24Hour,
          }),
          date: date.toLocaleDateString(i18n.language, {
            timeZone: zone,
            weekday: "short",
            month: "numeric",
            day: "numeric",
          }),
        };
      });
      setTimes(newTimes);
    };

    updateTimes();
    const interval = setInterval(updateTimes, 1000);
    return () => clearInterval(interval);
  }, [timeZones, use24Hour, setTimes, i18n.language]);
};
