import { useState } from "react";
import { THEMES, DEFAULT_CITIES, AVAILABLE_CITIES } from "../constants";

export const useClockState = () => {
  const [timeZones, setTimeZones] = useState(() => {
    const saved = localStorage.getItem("clockOrder");
    if (saved) {
      const savedCities = JSON.parse(saved);
      return savedCities.map(cityId => {
        const city = AVAILABLE_CITIES.find(c => c.id === cityId);
        return city || null;
      }).filter(Boolean);
    }
    return DEFAULT_CITIES.map(cityId => {
      const city = AVAILABLE_CITIES.find(c => c.id === cityId);
      return city || null;
    }).filter(Boolean);
  });

  const [times, setTimes] = useState({});
  const [showSettings, setShowSettings] = useState(false);
  const [use24Hour, setUse24Hour] = useState(() => {
    const saved = localStorage.getItem("use24Hour");
    return saved ? JSON.parse(saved) : true;
  });
  const [theme, setTheme] = useState(() => {
    const saved = localStorage.getItem("theme");
    return saved || THEMES.SYSTEM;
  });
  const [showAddCity, setShowAddCity] = useState(false);
  const [cityToRemove, setCityToRemove] = useState(null);

  return {
    timeZones,
    setTimeZones,
    times,
    setTimes,
    showSettings,
    setShowSettings,
    use24Hour,
    setUse24Hour,
    theme,
    setTheme,
    showAddCity,
    setShowAddCity,
    cityToRemove,
    setCityToRemove,
  };
};
