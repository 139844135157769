import React from 'react';
import { Button, Switch } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const Header = ({ use24Hour, setUse24Hour, setShowSettings }) => {
  const { t } = useTranslation();

  return (
    <header className="header">
      <div className="header-content">
        <h1>{t('title')}</h1>
        <div className="header-actions">
          <Switch
            size="small"
            checked={use24Hour}
            onChange={(checked) => {
              setUse24Hour(checked);
              localStorage.setItem('use24Hour', JSON.stringify(checked));
            }}
            checkedChildren={t('settings.timeFormat.24h')}
            unCheckedChildren={t('settings.timeFormat.12h')}
          />
          <Button
            type="text"
            icon={<SettingOutlined />}
            onClick={() => setShowSettings(true)}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;