import React, { useState, useEffect } from "react";
import { ConfigProvider, theme } from "antd";
import WorldClock from "./components/WorldClock";
import "./App.css";

function App() {
  const [isDark, setIsDark] = useState(() => {
    const saved = localStorage.getItem("theme");
    if (saved === "dark") return true;
    if (saved === "light") return false;
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => {
      if (localStorage.getItem("theme") === null) {
        setIsDark(e.matches);
      }
    };
    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", isDark ? "dark" : "light");
  }, [isDark]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: "#3b82f6",
          borderRadius: 8,
          colorBgContainer: isDark ? "#1f2937" : "#ffffff",
          colorBgElevated: isDark ? "#374151" : "#ffffff",
          colorText: isDark ? "#ffffff" : "#1f2937",
          colorTextSecondary: isDark ? "#9ca3af" : "#666666",
          colorBorder: isDark ? "#374151" : "#eaeaea",
          boxShadow: isDark 
            ? "0 4px 12px rgba(0, 0, 0, 0.2)" 
            : "0 4px 12px rgba(0, 0, 0, 0.08)",
        },
      }}
    >
      <div
        className="App"
        style={{
          backgroundColor: isDark ? "#111827" : "#f8fafc",
          minHeight: "100vh",
        }}
      >
        <WorldClock
          onThemeChange={(mode) => {
            setIsDark(mode === "dark");
            localStorage.setItem("theme", mode);
          }}
        />
      </div>
    </ConfigProvider>
  );
}

export default App;
