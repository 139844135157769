export const getTimeDiff = (timeZone) => {
  const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // 检查是否相同时区
  if (timeZone === localZone) {
    return "timeDiff.sameTimezone";
  }

  const localDate = new Date();
  const targetDate = new Date(localDate.toLocaleString("en-US", { timeZone }));
  const diffHours = Math.round((targetDate - localDate) / (1000 * 60 * 60));

  // 如果时差为0，返回相同时间
  if (diffHours === 0) {
    return "timeDiff.sameTime";
  }

  // 只显示小时差
  return {
    key: diffHours > 0 ? "timeDiff.ahead" : "timeDiff.behind",
    options: { hours: Math.abs(diffHours) }
  };
};

export const formatTime = (date, zone, use24Hour) => {
  return date.toLocaleTimeString("en-US", {
    timeZone: zone,
    hour12: !use24Hour,
    hour: "numeric",
    minute: "numeric",
  });
};
