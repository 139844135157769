import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'pt', name: 'Português' },
    { code: 'fr', name: 'Français' },
    { code: 'de', name: 'Deutsch' },
    { code: 'ar', name: 'العربية' },
    { code: 'ja', name: '日本語' },
    { code: 'zh-TW', name: '繁體中文' },
    { code: 'zh-CN', name: '简体中文' }
  ];

  const handleChange = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };

  return (
    <div className="setting-item">
      <div className="setting-header">
        <div className="setting-title">
          <span className="setting-label">{t('language.label')}</span>
        </div>
        <Select
          value={i18n.language}
          onChange={handleChange}
          style={{ width: 120 }}
        >
          {languages.map(lang => (
            <Option key={lang.code} value={lang.code}>
              {t(`language.${lang.code}`)}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default LanguageSelector;
