import React from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_CITIES } from './clock/constants';
import { useDragSensors } from './clock/hooks/useDragSensors';
import { useClockState } from './clock/hooks/useClockState';
import { useTimeUpdate } from './clock/hooks/useTimeUpdate';
import { useCityManagement } from './clock/hooks/useCityManagement';
import { useDragHandling } from './clock/hooks/useDragHandling';
import ClockCard from './clock/ClockCard';
import Header from './clock/Header';
import Settings from './clock/Settings';
import './WorldClock.css';

const WorldClock = ({ onThemeChange }) => {
  const { t } = useTranslation();
  const {
    timeZones,
    setTimeZones,
    times,
    setTimes,
    showSettings,
    setShowSettings,
    use24Hour,
    setUse24Hour,
    showDate,
    setShowDate,
    theme,
    setTheme,
    showAddCity,
    setShowAddCity,
  } = useClockState();

  const sensors = useDragSensors();
  const handleDragEnd = useDragHandling(setTimeZones);
  const { handleAddCity, handleRemoveCity, confirmRemove, cityToRemove, setCityToRemove } = useCityManagement(timeZones, setTimeZones);

  useTimeUpdate(timeZones, use24Hour, setTimes);

  const headerProps = {
    use24Hour,
    showDate,
    setUse24Hour,
    setShowDate,
    setShowSettings,
  };

  return (
    <div className="world-clock">
      <Header {...headerProps} />
      
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <div className="clock-container">
          <SortableContext items={timeZones.map(item => item.id)} strategy={rectSortingStrategy}>
            <div className="clock-grid">
              {timeZones.map((item) => (
                <ClockCard
                  key={item.id}
                  id={item.id}
                  city={item.city}
                  zone={item.zone}
                  time={times[item.city]?.time}
                  date={times[item.city]?.date}
                  showDate={showDate}
                  onRemove={handleRemoveCity}
                />
              ))}
            </div>
          </SortableContext>
        </div>
      </DndContext>

      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<PlusOutlined />}
        onClick={() => setShowAddCity(true)}
        className="floating-add-button"
      />

      <Modal
        title={t('addCity.title')}
        open={showAddCity}
        footer={null}
        onCancel={() => setShowAddCity(false)}
        className="add-city-modal"
        width={800}
      >
        <div className="city-list">
          {AVAILABLE_CITIES.filter(city => 
            !timeZones.some(tz => tz.id === city.id)
          ).map(city => (
            <Button
              key={city.id}
              type="text"
              onClick={() => {
                handleAddCity(city);
                setShowAddCity(false);
              }}
              className="city-option"
            >
              <span className="city-option-name">{t(`cities.${city.id}.name`, { defaultValue: city.city })}</span>
              <span className="city-option-country">{t(`cities.${city.id}.country`, { defaultValue: city.country })}</span>
            </Button>
          ))}
        </div>
      </Modal>

      <Modal
        title={t('removeCity.title', { defaultValue: 'Remove City' })}
        open={!!cityToRemove}
        onOk={confirmRemove}
        onCancel={() => setCityToRemove(null)}
        okText={t('removeCity.confirm', { defaultValue: 'Remove' })}
        cancelText={t('removeCity.cancel', { defaultValue: 'Cancel' })}
        okButtonProps={{ danger: true }}
      >
        <p>
          {t('removeCity.message', {
            defaultValue: 'Are you sure you want to remove {{city}}?',
            city: cityToRemove ? t(`cities.${cityToRemove.id}.name`, { defaultValue: cityToRemove.city }) : ''
          })}
        </p>
      </Modal>

      <Settings
        open={showSettings}
        onClose={() => setShowSettings(false)}
        theme={theme}
        use24Hour={use24Hour}
        showDate={showDate}
        onThemeChange={onThemeChange}
        setTheme={setTheme}
        setUse24Hour={setUse24Hour}
        setShowDate={setShowDate}
      />
    </div>
  );
};

export default WorldClock;