import { arrayMove } from "@dnd-kit/sortable";

export const useDragHandling = (setTimeZones) => {
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setTimeZones((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);
        localStorage.setItem("clockOrder", JSON.stringify(newItems));
        return newItems;
      });
    }
  };

  return handleDragEnd;
};
