import { useState, useEffect } from "react";
import { AVAILABLE_CITIES } from "../constants";

export const useCityManagement = (timeZones, setTimeZones) => {
  const [cityToRemove, setCityToRemove] = useState(null);

  // 保存城市顺序到 localStorage
  useEffect(() => {
    const cityIds = timeZones.map(tz => tz.id);
    localStorage.setItem("clockOrder", JSON.stringify(cityIds));
  }, [timeZones]);

  const handleAddCity = (selectedCity) => {
    const cityData = AVAILABLE_CITIES.find(city => city.id === selectedCity.id);
    if (cityData) {
      setTimeZones(prev => [cityData, ...prev]);
    }
  };

  const handleRemoveCity = (id) => {
    const city = timeZones.find(tz => tz.id === id);
    if (city) {
      setCityToRemove(city);
    }
  };

  const confirmRemove = () => {
    if (cityToRemove) {
      setTimeZones(prev => prev.filter(tz => tz.id !== cityToRemove.id));
      setCityToRemove(null);
    }
  };

  return {
    handleAddCity,
    handleRemoveCity,
    confirmRemove,
    cityToRemove,
    setCityToRemove,
  };
};
