import React from 'react';
import { Card, Button } from 'antd';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { getTimeDiff } from './utils';
import { AVAILABLE_CITIES } from './constants';
import { useTranslation } from 'react-i18next';

const ClockCard = ({ id, city, zone, time, date, onRemove }) => {
  const { t } = useTranslation();
  const cityData = AVAILABLE_CITIES.find(c => c.id === id);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    zIndex: isDragging ? 1 : undefined,
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onRemove(id);  // 只传递 id
  };

  return (
    <div ref={setNodeRef} style={style} className={`clock-card-wrapper ${isDragging ? 'dragging' : ''}`}>
      <Card
        className="clock-card"
        bordered={false}
        extra={
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={handleDelete}
            className="clock-card-delete"
          />
        }
        title={
          <div className="clock-header">
            <div {...listeners} {...attributes} style={{ cursor: 'grab', display: 'flex', alignItems: 'center' }}>
              <MenuOutlined style={{ marginRight: 8, opacity: 0.6 }} />
            </div>
            <div className="city-info">
              <span className="city-name">{t(`cities.${id}.name`, { defaultValue: cityData?.city || city })}</span>
              <span className="country-name">{t(`cities.${id}.country`, { defaultValue: cityData?.country || '' })}</span>
              {(() => {
                const timeDiff = getTimeDiff(zone);
                return (
                  <span className="time-diff">
                    {typeof timeDiff === 'string' 
                      ? t(timeDiff)
                      : t(timeDiff.key, timeDiff.options)
                    }
                  </span>
                );
              })()}
            </div>
          </div>
        }
      >
        <div className="time">{time}</div>
        <div className="date">{date}</div>
      </Card>
    </div>
  );
};

export default ClockCard;