import React from 'react';
import { Modal, Switch, Select, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const Settings = ({
  open,
  onClose,
  theme,
  use24Hour,
  onThemeChange,
  setTheme,
  setUse24Hour,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('settings.title')}
      open={open}
      onCancel={onClose}
      footer={null}
      className="settings-modal"
      centered
      width={480}
    >
      <div className="settings-content">
        <div className="settings-section">
          <h3 className="settings-section-title">{t('settings.timeFormat.label')}</h3>
          <div className="settings-item">
            <span className="settings-label">{t('settings.timeFormat.24h')}</span>
            <Switch checked={use24Hour} onChange={setUse24Hour} />
          </div>
        </div>

        <Divider className="settings-divider" />

        <div className="settings-section">
          <h3 className="settings-section-title">{t('settings.theme.label')}</h3>
          <div className="settings-item">
            <span className="settings-label">{t('settings.theme.description')}</span>
            <Select
              value={theme}
              onChange={(value) => {
                setTheme(value);
                onThemeChange(value);
              }}
              style={{ width: 160 }}
            >
              <Select.Option value="light">{t('settings.theme.light')}</Select.Option>
              <Select.Option value="dark">{t('settings.theme.dark')}</Select.Option>
            </Select>
          </div>
        </div>

        <Divider className="settings-divider" />

        <div className="settings-section">
          <h3 className="settings-section-title">{t('settings.language.label')}</h3>
          <LanguageSelector />
        </div>
      </div>
    </Modal>
  );
};

export default Settings;